import React from 'react';
import { Document } from '@contentful/rich-text-types';
import FlexibleLink from '../../ui/FlexibleLink';
import Headline from '../../ui/Headline';
import RichText from '../../ui/RichText';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import { ILink } from '../../ui/FlexibleLink/type';
import { IImage } from '../../ui/Image/types';
import Image from '../../ui/Image';
import * as styles from './styles.module.scss';

type PartnerTeaserProps = {
  headline: string;
  sectionHeadline?: string;
  subHeadline?: string;
  image: IImage;
  link?: ILink;
  text: Document;
  partnerLogo?: 'IImage';
};

const PartnerTeaser: React.FC<PartnerTeaserProps> = ({
  headline,
  subHeadline,
  image,
  link,
  text,
  partnerLogo,
  sectionHeadline,
}) => {
  return (
    <Container additionalClass={styles.container}>
      {sectionHeadline && (
        <div className="text-center mb-50 desktop:mb-100 w-2/3 mx-auto">
          <Headline level={2} color="Black">
            {sectionHeadline}
          </Headline>
        </div>
      )}
      <Row>
        <Col tablet={6} mobile={12} additionalClass={styles.textCol}>
          {partnerLogo && <Image {...partnerLogo} additionalClass={styles.partnerLogo} />}
          <Headline level={2} additionalClass="pb-25" color="Black">
            {headline}
          </Headline>
          {subHeadline && <p className={styles.subHeadline}>{subHeadline}</p>}
          <div className={styles.textWrapper}>
            <RichText content={text} additionalClass={styles.text} />
          </div>
          {link && <FlexibleLink link={link} />}
        </Col>
        <Col tablet={6} mobile={12} additionalClass={styles.imgCol}>
          <Image {...image} additionalClass={styles.img} />
        </Col>
      </Row>
    </Container>
  );
};

export default PartnerTeaser;
